import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const MaterialIcon = ({ className, icon, onClick }) => (
  <i
    className={ `material-icons ${styles.icon} ${className || ''}` }
    data-icon={ icon }
    onClick={ onClick }
  />
);

MaterialIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

MaterialIcon.defaultProps = {
  className: '',
  onClick: () => {},
};

export default MaterialIcon;
