import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'sw-ui';

import { DATES } from '../../constants/dates';
import COMPONENTS from '../../bi/constants/components';
import { ACCOUNTTEXT } from '../../bi/constants/account';

import styles from './styles/index.module.css';

const RangeDatePicker = ({
  nullableFrom,
  nullableTo,
  startDate,
  endDate,
  onChange,
  disabledFrom,
  disabledTo,
  placeholderNameFrom,
  placeholderNameTo,
  toCol,
  StartDatelabel,
  EndDatelabel,
  labelName,
  fieldEnd,
  fieldStart,
  maxDateLimit,
  placeholderClassName,
}) => (
  <div className={ toCol ? styles.col : styles.wrap }>
    <label className={ styles.label_name }>{ labelName }</label>
    <div className={ placeholderClassName }>{ placeholderNameFrom }</div>
    <DatePicker
      nullable={ nullableFrom }
      placeholder={ ACCOUNTTEXT.FROM }
      className={ styles.date }
      type={ COMPONENTS.DATEPICKER.TYPE.DATE }
      inputTheme={ COMPONENTS.DATEPICKER.INPUTTHEME.OPEN }
      value={ startDate }
      onChange={ ({ value }) => onChange(fieldStart || DATES.STARTDATE, value) }
      maxDate={ maxDateLimit }
      disabled={ disabledFrom }
      label={ StartDatelabel }
    />
    <div className={ placeholderClassName }>{ placeholderNameTo }</div>
    <DatePicker
      nullable={ nullableTo }
      placeholder={ ACCOUNTTEXT.TO }
      className={ styles.date }
      type={ COMPONENTS.DATEPICKER.TYPE.DATE }
      inputTheme={ COMPONENTS.DATEPICKER.INPUTTHEME.OPEN }
      value={ endDate }
      onChange={ ({ value }) => onChange(fieldEnd || DATES.ENDDATE, value) }
      minDate={ startDate }
      maxDate={ maxDateLimit }
      disabled={ disabledTo }
      label={ EndDatelabel }
    />
  </div>
);

RangeDatePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabledFrom: PropTypes.bool,
  disabledTo: PropTypes.bool,
  nullableFrom: PropTypes.bool,
  nullableTo: PropTypes.bool,
  placeholderNameFrom: PropTypes.string,
  placeholderNameTo: PropTypes.string,
  StartDatelabel: PropTypes.string,
  EndDatelabel: PropTypes.string,
  labelName: PropTypes.string,
  fieldEnd: PropTypes.string,
  fieldStart: PropTypes.string,
  toCol: PropTypes.bool,
  maxDateLimit: PropTypes.object,
  placeholderClassName: PropTypes.string,
};

RangeDatePicker.defaultProps = {
  disabledFrom: false,
  disabledTo: false,
  nullableFrom: false,
  nullableTo: false,
  placeholderNameFrom: '',
  placeholderNameTo: '',
  toCol: false,
  endDate: null,
  startDate: null,
  StartDatelabel: '',
  EndDatelabel: '',
  labelName: '',
  fieldEnd: '',
  fieldStart: '',
  maxDateLimit: null,
  placeholderClassName: '',
};

export default RangeDatePicker;
