import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'sw-ui';

import { getMoment } from '../../bi/utils/formatDate';
import { ACCOUNTTEXT } from '../../bi/constants/account';
import COMPONENTS from '../../bi/constants/components';
import SEARCH from '../../bi/constants/search';

import AjaxButton from '../ajaxButton';

import styles from './styles/index.module.scss';

const inputLabels = {
  date: 'Дата',
};

class Overdraft extends Component {
  static propTypes = {
    date: PropTypes.object,
    description: PropTypes.string,
    labelButton: PropTypes.string,
    disabledButton: PropTypes.bool,
    onDownload: PropTypes.func.isRequired,
  };

  static defaultProps = {
    date: getMoment(),
    description: '',
    labelButton: SEARCH.DOWNLOAD,
    disabledButton: false,
  };

  state = {
    waitingResponse: false,
    date: this.props.date,
  };

  handleChangeDate = ({ value }) => {
    this.setState({
      date: value,
    });
  }

  toggleWaitingResponse = () => this.setState({ waitingResponse: !this.state.waitingResponse });

  handleDownload = () => {
    const { date } = this.state;

    this.toggleWaitingResponse();

    return this.props.onDownload(date)
      .then(this.toggleWaitingResponse)
      .catch(this.toggleWaitingResponse);
  };

  render() {
    const { waitingResponse, date } = this.state;
    const { description, labelButton, disabledButton } = this.props;

    const descriptionHtml = description.length > 0 && <div className={ styles.description }>{ description }</div>;

    return (
      <div className={ styles.main }>
        { descriptionHtml }
        <div className={ styles.header }>
          <div className={ styles.search }>
            <DatePicker
              placeholder={ ACCOUNTTEXT.FROM }
              className={ styles.date }
              type={ COMPONENTS.DATEPICKER.TYPE.DATE }
              inputTheme={ COMPONENTS.DATEPICKER.INPUTTHEME.OPEN }
              value={ date }
              onChange={ this.handleChangeDate }
              label={ inputLabels.date }
            />
            <div className={ `${styles.actions} sw-tooltip-wrapper` }>
              <AjaxButton
                label={ labelButton }
                disabled={ disabledButton }
                loading={ waitingResponse }
                onClick={ this.handleDownload }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Overdraft;
