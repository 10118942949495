import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Dialog, RadioButton } from 'sw-ui';
import DecisionButtons from '../DecisionButtons';

import styles from './styles/index.module.css';

class RadioButtonsDialog extends Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    buttonValue: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    list: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      checkedValue: props.buttonValue,
    };
  }

  handleSwitchRadio = value => this.setState({
    checkedValue: value,
  });

  renderItem = (item, ind) => {
    const { checkedValue } = this.state;
    const { value, label } = item;

    return (
      <div
        key={ ind + value }
        className={ styles.radio }
      >
        <RadioButton
          checked={ checkedValue === value }
          onChange={ () => this.handleSwitchRadio(value) }
        >
          { label }
        </RadioButton>
      </div>
    );
  };

  render() {
    const { width, header, onClose, onSave, list } = this.props;
    const { checkedValue } = this.state;

    return (
      <Dialog
        onClick={ onClose }
        width={ width }
      >
        <div className={ styles.dialog }>
          <div className={ styles.header }> { header } </div>
          <div>
            <div className={ styles.radios }>
              { list.map((item, ind) => this.renderItem(item, ind)) }
            </div>
          </div>
          <div className={ styles.actions }>
            <div className={ styles.action }>
              <DecisionButtons
                loadingIncluded
                onSave={ () => onSave(checkedValue) }
                onCancel={ onClose }
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default RadioButtonsDialog;
