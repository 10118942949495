import PropTypes from 'prop-types';
import React from 'react';

import arrayWithSeparators from '../../bi/utils/arrayWithSeparators';
import guid from '../../bi/utils/guid';
import { prepareRefundable, prepareBaggage, prepareCarryOn, refundTicketWithCondition, prepareChangeable, prepareRefundableAfterDeparture, prepareChangeableAfterDeparture } from '../../bi/utils/fares';

import { CHANGETICKET, VALUETICKET, CARRYON, BAGGAGE, TARIFFS } from '../../bi/constants/airline';

import styles from './styles/index.module.css';

const renderItem = (label, value) => {
  if (value) {
    return (
      <li className={ styles.item }>
        <b>{ label }:&nbsp;</b>
        { value }
      </li>
    );
  }

  return null;
};

const FareDetails = ({ fare, title, separateSymbol, asItem, customTitle }) => {
  const {
    Name,
    IsBaggageIncluded,
    BaggagePlaces,
    IsCarryonIncluded,
    CarryonPlaces,
    IsTicketRefundable,
    IsTicketChangeable,
    IsTicketRefundableAfterDeparture,
    IsTicketChangeableAfterDeparture,
    CanRegistrationSeat,
    IsBusinessHallIncluded,
    CanUpgradeRate,
    AirlineBonusInformation,
    RefundTicketWithCondition,
    ChangeTicketWithCondition,
  } = fare;
  let details = [];

  if (CanRegistrationSeat === VALUETICKET.INCLUDED) {
    details.push('Выбор места: включено');
  }

  if (CanRegistrationSeat === VALUETICKET.CHARGE) {
    details.push('Выбор места: платно');
  }

  if (IsBusinessHallIncluded === VALUETICKET.INCLUDED) {
    details.push('Бизнес зал: включен');
  }

  if (IsBusinessHallIncluded === VALUETICKET.NOTOFFERED) {
    details.push('Бизнес зал: не включен');
  }

  if (CanUpgradeRate === VALUETICKET.INCLUDED) {
    details.push('доступно повышение тарифа');
  }

  if (AirlineBonusInformation) {
    details.push(AirlineBonusInformation);
  }

  if (RefundTicketWithCondition) {
    refundTicketWithCondition(details);
  }

  if (ChangeTicketWithCondition) {
    Object.keys(ChangeTicketWithCondition).forEach((field) => {
      if (CHANGETICKET[ChangeTicketWithCondition[field]]) {
        details.push(`${TARIFFS.CHANGE} ${field}: ${CHANGETICKET[ChangeTicketWithCondition[field]]}`);
      }
    });
  }

  const baggageValue = prepareBaggage(IsBaggageIncluded, BaggagePlaces);
  const carryOnValue = prepareCarryOn(IsCarryonIncluded, CarryonPlaces);
  const refundableValue = prepareRefundable(IsTicketRefundable);
  const refundableAfterDeparture = prepareRefundableAfterDeparture(IsTicketRefundableAfterDeparture);
  const changeableValue = prepareChangeable(IsTicketChangeable);
  const changeableAfterDeparture = prepareChangeableAfterDeparture(IsTicketChangeableAfterDeparture);
  const fareNameValue = customTitle || (() => (title ? Name : ''))();

  if (refundableAfterDeparture) {
    details.push(`${TARIFFS.RETURNAFTEREDEPARTURE}${refundableAfterDeparture}`);
  }

  if (changeableAfterDeparture) {
    details.push(`${TARIFFS.CHANGEAFTERDEPARTURE}${changeableAfterDeparture}`);
  }

  if (separateSymbol) {
    details = arrayWithSeparators(details, separateSymbol.value);
  }

  const fareStyles = [styles.fare];

  if (asItem) {
    fareStyles.push(styles.fare_as_item);
  }

  const detailsContent = details.map((detail, index) => (
    <div
      key={ `fare_tooltip_${guid()}_${index}` }
      className={ `${fareStyles.join(' ')}` }
    >
      { detail }
    </div>
  ));

  const nameContent = renderItem(TARIFFS.TARIFFSONLY, fareNameValue);
  const baggageContent = renderItem(BAGGAGE.ONLYBAGGAGE, baggageValue);
  const carryOnContent = renderItem(CARRYON.CARRYONFREE, carryOnValue);
  const refundableContent = renderItem(TARIFFS.RETURNBEFOREDEPARTURE, refundableValue);
  const changeableContent = renderItem(TARIFFS.CHANGEBEFOREDEPARTURE, changeableValue);

  return (
    <div className={ `${styles.wrap} ${styles['cart-view']}` }>
      <ul>
        { nameContent }
        { baggageContent }
        { carryOnContent }
        { refundableContent }
        { changeableContent }
      </ul>
      <div className={ styles.details }>
        { detailsContent }
      </div>
    </div>
  );
};

FareDetails.propTypes = {
  fare: PropTypes.object.isRequired,
  title: PropTypes.bool,
  separateSymbol: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  }),
  asItem: PropTypes.bool,
  customTitle: PropTypes.string,
};

FareDetails.defaultProps = {
  title: false,
  separateSymbol: null,
  asItem: false,
  customTitle: '',
};

export default FareDetails;
