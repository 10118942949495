import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './loaders.module.css';

class CircularLoaders extends Component {
  constructor(props) {
    super(props);

    let { cx, cy, r, strokeWidth, wrapWidth } = props;

    cx = cx || 50;
    cy = cy || 50;
    r = r || 20;
    strokeWidth = strokeWidth || 2;
    wrapWidth = wrapWidth || 100;
    const viewBox = `${cx / 2} ${cy / 2} ${cx} ${cy}`;

    this.state = {
      cx,
      cy,
      r,
      strokeWidth,
      viewBox,
      wrapWidth,
    };
  }

  render() {
    const { wrapClassName } = this.props;
    const { cx, cy, r, strokeWidth, wrapWidth, viewBox } = this.state;
    const style = { width: wrapWidth };

    return (
      <div className={ `${styles.loader} ${wrapClassName || ''}` } style={ style }>
        <svg className={ styles.circular } viewBox={ viewBox }>
          <circle
            className={ styles.path }
            cx={ cx }
            cy={ cy }
            r={ r }
            fill='none'
            strokeWidth={ strokeWidth }
            strokeMiterlimit='10'
          />
        </svg>
      </div>
    );
  }
}

CircularLoaders.propTypes = {
  // eslint-disable-next-line
  wrapClassName: PropTypes.string,
  // eslint-disable-next-line
  cx: PropTypes.number,
  // eslint-disable-next-line
  cy: PropTypes.number,
  // eslint-disable-next-line
  r: PropTypes.number,
  // eslint-disable-next-line
  strokeWidth: PropTypes.number,
  // eslint-disable-next-line
  wrapWidth: PropTypes.number,
};

export default CircularLoaders;

