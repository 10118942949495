import React, { Component } from 'react';
import PropTypes from 'prop-types';

const HAS_VALUE = 'has-value';

class Input extends Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    valid: PropTypes.string,
    isNewProps: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    disabled: PropTypes.bool,
    labelDropDown: PropTypes.bool,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    clue: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    type: 'text',
    valid: '',
    clue: '',
    isNewProps: false,
    value: '',
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onKeyDown: () => {},
    disabled: false,
    labelDropDown: false,
    placeholder: '',
    className: '',
  };

  constructor(props) {
    super(props);
    const { type, value, placeholder } = props;
    const isZero = Number(value) === 0 ? true : Boolean(value); // херня конечно
    this.state = {
      hasValue: type === 'number' ? !Number.isNaN(parseFloat(value, 10)) : isZero,
      placeholder,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.isNewProps && Boolean(newProps.value) !== this.state.hasValue) {
      this.setState({ hasValue: Boolean(newProps.value) });
    }
  }

  updateState = value => this.setState({ hasValue: value });

  onChange = (e) => {
    let val = e.target.value;
    const hasValue = Boolean(val);

    this.setState({
      hasValue,
    });

    if (this.props.type === 'number' && val !== '') {
      const value = parseFloat(val, 10);

      val = Number.isNaN(value) ? 0 : value;
    }

    if (this.props.onChange) {
      this.props.onChange(e, this.props.field, val);
    }
  };

  onFocus = (e) => {
    this.setState({ placeholder: '' });
    if (this.props.onFocus) {
      this.props.onFocus(e, this.props.field);
    }
  };

  onBlur = (e) => {
    const { field, label, placeholder, onBlur } = this.props;

    this.setState({ placeholder });
    if (onBlur) {
      onBlur(e, field, label);
    }
  };

  render() {
    const { type, value, valid, disabled, className, labelDropDown, clue, onKeyDown } = this.props;
    const { placeholder } = this.state;
    const validHtml = valid ? <span className='error-msg'>{ valid }</span> : null;
    const clueHtml = clue ? <span className='clue'>{ clue }</span> : null;

    const onWheelHandel = this.props.type === 'number' ?
      event => event.currentTarget.blur() :
      () => {};

    const classNames = ['form-group'];
    const labelClassNames = placeholder || disabled || labelDropDown ? 'no-placeholder' : '';

    if (valid) {
      classNames.push('no-valid');
    }

    if (className) {
      classNames.push(className);
    }

    const hasValue = (value && String(value).length > 0);

    return (
      <div className={ classNames.join(' ') }>
        <input
          disabled={ disabled }
          type={ type }
          className={ `al-input ${(this.state.hasValue || hasValue) && HAS_VALUE}` }
          required
          onChange={ this.onChange }
          onFocus={ this.onFocus }
          onBlur={ this.onBlur }
          onKeyDown={ onKeyDown }
          placeholder={ placeholder }
          onWheel={ onWheelHandel }
          value={ value || value === 0 ? value : '' } // @TODO: херня какая-то
        />
        <label className={ labelClassNames }>{ this.props.label }</label>
        { validHtml }
        { clueHtml }
      </div>
    );
  }
}

export default Input;
