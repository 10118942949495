import React from 'react';
import PropTypes from 'prop-types';

import './styles/index.css';

const LinkToNewTab = ({ to, children }) => (
  <a href={ to } target='_blank' rel='noreferrer'>
    { children }
  </a>
);

LinkToNewTab.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LinkToNewTab;
