import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import styles from './profile.module.css';
import COMPONENTS from '../../bi/constants/components';

const ProfileCard = (props) => {
  const { userName } = props;

  return (
    <div className={ `sw-tooltip-wrapper ${styles['profile-card']}` }>
      <div className={ styles.name }>
        { userName }
      </div>
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
      >
        { userName }
      </Tooltip>
    </div>
  );
};


ProfileCard.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default ProfileCard;
